<template>
    <div>
        Show medical
        {{ code }}
    </div>
</template>

<script>
    export default {
        name: "Show",
        props: {
            code: String|Number
        }
    }
</script>

<style scoped>

</style>
