<template>
    <div class="activity-item">
        <div class="users">
            <div class="avatars">
<!--                <img class="participant" src="assets/img/avatars/1.jpg" alt="">-->
                <div class="participant is-fake is-orange">
                    <span>{{ data.name| ABC }}</span>
                </div>
            </div>
            <div class="meta">
                <span>{{ data.name }}</span>
                <span>{{data.code}}</span>
            </div>
        </div>
        <div class="details">
            <div class="users">
            <div class="meta">
                <span>Email</span>
                <span>{{ data.email }}</span>
            </div>
            </div>
            <!--            <order-file-drop-down v-if="data.pdf"-->
            <!--                                  :data="data"></order-file-drop-down>-->
            <!--/partials/dropdowns/dashboard-activity-item-dropdown.html-->
            <div class="dropdown-wrap is-right"
                 :class="{'is-active': toggle}"
                 v-click-outside="onClickOutside">
                <button  class="dropdown-button" @click="toggle = ! toggle">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="feather feather-more-horizontal">
                        <circle cx="12" cy="12" r="1"></circle>
                        <circle cx="19" cy="12" r="1"></circle>
                        <circle cx="5" cy="12" r="1"></circle>
                    </svg>
                </button>
                <div class="drop-menu">
                    <div class="menu-inner">
                        <div class="menu-header">
                            <h3>Actions</h3>
                        </div>
                        <div class="options-items">
                            <router-link :to="{name:'medical-show', params:{code:data.code}}" class="options-item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                     stroke-linejoin="round" class="feather feather-file-text">
                                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                    <polyline points="14 2 14 8 20 8"></polyline>
                                    <line x1="16" y1="13" x2="8" y2="13"></line>
                                    <line x1="16" y1="17" x2="8" y2="17"></line>
                                    <polyline points="10 9 9 9 8 9"></polyline>
                                </svg>
                                <div class="option-content">
                                    <span>Editar </span>
                                    <span>{{data.name}}</span>
                                </div>
                            </router-link>
                            <router-link :to="{name:'medical-show', params:{code:data.code}}" class="options-item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                     stroke-linejoin="round" class="feather feather-check-circle">
                                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                </svg>
                                <div class="option-content">
                                    <span>Ver detalles</span>
                                    <span>View task details</span>
                                </div>
                            </router-link>
                            <router-link :to="{name:'medical-show', params:{code:data.code}}" class="options-item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                     stroke-linejoin="round" class="feather feather-trash-2">
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                    <line x1="10" y1="11" x2="10" y2="17"></line>
                                    <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                                <div class="option-content">
                                    <span>Eliminar</span>
                                    <span>Eliminar este registro</span>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


    export default {
        name: "MedicListItem",
        data() {
            return {
                toggle: false,
            }
        },
        props: {
            data: Object
        },
        methods: {
            onClickOutside() {
                this.toggle = false;
            }
        }
    }
</script>

<style scoped>

</style>
