<template>

    <div>
        <div class="section-title">
            <h3>Medicos</h3>
            <router-link class="button is-success is-rounded is-small ml-auto"
                         :to="{name:'medical-create'}">
                {{ $t('medical.create.action')  }}
            </router-link>
        </div>
        <div  v-if="loading">
            <order-loading
                    v-for="item in 5"
                    :key="`order-holder-${item}`"
            ></order-loading>
        </div>
        <div class="activity-items" v-else>
            <medic-list-item
                    v-for="item in data"
                    :key="item.id"
                    :data="item"
            ></medic-list-item>
            <b-pagination
                    :total="total"
                    rounded
                    :per-page="params.perPage"
                    :current.sync="params.page"
                    @change="prepareComponent"
                    aria-next-label="Next page"
                    aria-previous-label="Previous page"
                    aria-page-label="Page"
                    aria-current-label="Current page">
            </b-pagination>
        </div>
    </div>
</template>

<script>
    import MedicListItem from "../../components/medical/MedicListItem";
    import OrderLoading from "../../components/order/OrderLoading";
    export default {
        name: "Index",
        components:{ OrderLoading,MedicListItem},
        data() {
            return {
                loading: true,
                data: [],
                params: {
                    page: 1,
                    perPage: 10,
                },
                total: 0,
                apiUrl: 'medicals',
            }
        },
        mounted() {
            this.prepareComponent();
        },
        methods: {
            async prepareComponent() {
                this.loading = true;
                await this.getAll(this.params)
                    .then(({data = [], meta = {}}) => {
                        this.data = data
                        this.total = meta.total || 0;
                    });
                this.loading = false;
            },
            onSorting(val, direction) {
                this.params.sort = this.sortableFields[val];
                this.params.direction = direction;
                this.prepareComponent();
            }
        }
    }
</script>

<style scoped>

</style>
