<template>
    <div>
        <content-placeholders
        >
            <content-placeholders-heading :img="true" />
            <content-placeholders-text :lines="3" />
        </content-placeholders>
    </div>
</template>

<script>
    export default {
        name: "OrderLoading"
    }
</script>

<style scoped>

</style>